import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation, } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators, FormControl, } from "@angular/forms";
import { environment } from "src/environments/environment";
import { DatePipe, KeyValue } from "@angular/common";
import { DateToAgePipe } from "../../pipes/date-to-age.pipe";
import { ApiService } from "../../services/api.service";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS, MAT_DATE_LOCALE, } from "@angular/material/core";
import { SharedDataService } from "src/app/services/shared-data.service";
import { take } from "rxjs";
import { DialogData } from "src/app/model/data.model";
import { CommonModalService } from "src/app/services/common-modal.service";
import { MatCheckboxChange } from "@angular/material/checkbox";
export const MOMENT_DATE_FORMATS: MatDateFormats = { parse: { dateInput: "D/MM/YYYY" }, display: { dateInput: "DD/MM/YYYY", monthYearLabel: "MMMM Y", dateA11yLabel: "LL", monthYearA11yLabel: "MMMM Y", }, };
import { NgxCaptureService } from "ngx-capture";
import { tap } from "rxjs/operators";
import { ApiConstants } from "src/app/api.constant";
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: "app-quotes-list",
  templateUrl: "./quotes-list.component.html",
  styleUrls: ["./quotes-list.component.scss"],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE], },
    { provide: MAT_DATE_FORMATS, useValue: MOMENT_DATE_FORMATS },
  ],
  encapsulation: ViewEncapsulation.None,
})

export class QuotesListComponent implements OnInit {
  @ViewChild("screen", { static: true }) screen: any;
  @Output() toggelValue = new EventEmitter<string>();
  planCombo = [
    { key: "Wellness", value: "Wellness", isChecked: false },
    {
      key: "Wellness + Health Insurance",
      value: "Wellness + Health Insurance",
      isChecked: true,
    },
  ];

  selectedPaymentPeriod: any;
  paymentOption = [
    { key: "EZPay", value: "EazyPay", isChecked: false },
    { key: "Regular", value: "Regular" , isChecked: true},
  ];
  
  selectedPaymentOption = this.paymentOption.find(option => option.isChecked)?.value || 'Regular';

  paymentPeriod = [
    // { key: "3 Months", value: "3" },
    { key: "6 Months", value: "6" },
    { key: "10 Months", value: "10" },
  ];

  familySize = [
    { key: "1,0", value: "1 Adult" },
    { key: "2,0", value: "2 Adult" },
    { key: "2,1", value: "2 Adult + 1 Child" },
    { key: "2,2", value: "2 Adult + 2 Child" },
  ];

  @Input() dialogRef: any = "";
  @Input() isChangePlanModal: boolean = false;
  public policy_number: any;
  pincode: any = 110001;
  comparepopup = false;
  minDate = new Date();
  maxDate = new Date();
  chooseplanForm!: FormGroup;
  age: any = 0;
  date: any;
  DOB: any;
  pincodeList: any;
  selectedPincodeList: any;
  quotesList: KeyValue<any, any>[] = [];
  isCheckDOB: boolean = false;
  planData: any;
  isPincode: boolean = true;
  checkPincodeEidelweiss: boolean = false;
  overlayPopup: boolean = false;
  baseURl: string = "";
  old_policy_no: any;
  checkPincodeLiberty: boolean = false;
  checkPincodeGoDigit: boolean = false;
  isSpinner: boolean = true;
  blockInsurer: any = [];
  isAllBlockInsurers: boolean = false;
  countAge: any;
  showfamilySize: any = this.familySize[0].value;
  quotesFooter: any;
  provider: any;
  isLiberty: boolean = false;
  isZuno: boolean = false;
  isDigit: boolean = false;
  providerName: string = "Digit";
  submitFormLoader: boolean = false;
  encrypted: any;
  exist_orderId: any;
  isCustomerMobileModalOpen: boolean = false;
  cachedData = {};
  checkedIndexes: number[] = [];
  headerList: any = [];
  isHidden: boolean = false;
  checkedColumnIndexes: number[] = [];
  isColHidden: boolean = false;
  img = "";
  body = document.body;
  is_wellness: boolean = false;
  isRenewable: boolean = false;
  proceedWithEazyPay: boolean = false;
  brochureUrlObject: any = {};
  proceedWithMagma: boolean = false;
  proceedWithZuno: boolean = true; ;
  addOnId : any ;
  wellness_hover_text: string = "Wellness Plan is an one-stop solution for customers who seek OPD benefits like Doctor Consultations, Health Check-up, Lab Tests, Online Medicines etc";
  combo_plan_hover_text: string = "This is a comprehensive health plan of Wellness Plan with hospitalization cover up to INR 10 lacs.";

  previousPolicy: { modalType: number; widthObtained: string; heightObtained: string; topObtained: string; classObtained: string; } = {
    modalType: 22,
    widthObtained: "50%",
    heightObtained: "auto",
    topObtained: "auto",
    classObtained: "policyAlertClasss",
  };

  tenMonthsBimapayMagma: {
    modalType: number;
    widthObtained: string;
    heightObtained: string;
    topObtained: string;
    classObtained: string;
  } = {
    modalType: 36,
    widthObtained: "30%",
    heightObtained: "auto",
    topObtained: "auto",
    classObtained: "BimapayTenMonthPopComponent",
  };

  errorMsgModalJson: { modalType: number; widthObtained: string; heightObtained: string; topObtained: string; classObtained: string; } = {
    modalType: 3,
    widthObtained: "75%",
    heightObtained: "auto",
    topObtained: "auto",
    classObtained: "errorMsgPopupClass",
  };

  checkPincode: { modalType: number; widthObtained: string; heightObtained: string; topObtained: string; classObtained: string; } = {
    modalType: 25,
    widthObtained: "70%",
    heightObtained: "auto",
    topObtained: "auto",
    classObtained: "check-pincode-modal",
  };

  shareObj: { modalType: number; widthObtained: string; heightObtained: string; topObtained: string; classObtained: string; } = {
    modalType: 27,
    widthObtained: "70%",
    heightObtained: "auto",
    topObtained: "auto",
    classObtained: "check-pincode-modal",
  };

  customermobile: { modalType: number; widthObtained: string; heightObtained: string; topObtained: string; classObtained: string; } = {
    modalType: 13,
    widthObtained: "50%",
    heightObtained: "auto",
    topObtained: "auto",
    classObtained: "customermobilepopup",
  };

  eazyPayPopup: { modalType: number; widthObtained: string; heightObtained: string; topObtained: string; classObtained: string; } = {
    modalType: 33,
    widthObtained: "65",
    heightObtained: "auto",
    topObtained: "auto",
    classObtained: "eazypay-payment-option",
  };

  magmaPopup: { modalType: number; widthObtained: string; heightObtained: string; topObtained: string; classObtained: string; } = {
    modalType: 34,
    widthObtained: "65",
    heightObtained: "auto",
    topObtained: "auto",
    classObtained: "magma-renewals-popup",
  };
  isPopupShown: boolean = false; 
  getLazyPayResponse: any;
  isPortedToMagma: boolean = false; // Initially off
  magmaJourneyForUpgrade: any;
  urlEndPoint: string = "";


  constructor(
    private dialog: MatDialog,
    private router: Router,
    private formBuilder: FormBuilder,
    private dateToAgePipe: DateToAgePipe,
    private datePipe: DatePipe,
    private apiService: ApiService,
    private sharedDataService: SharedDataService,
    private commonModal: CommonModalService,
    private captureService: NgxCaptureService,
    private activatedRoute: ActivatedRoute
  ) {
    this.urlEndPoint = location.hash.split('/')[1];
    this.sharedDataService.clearPaymentData();
    sessionStorage.removeItem('maternityPremium')
    sessionStorage.removeItem( "maternityAddons"),
    sessionStorage.removeItem( "is_customer_upgrade_journey"),
    sessionStorage.removeItem("easyPayEligibilityConfirmData");
    sessionStorage.removeItem("planData");
    sessionStorage.removeItem("tenXTopup");
    this.sharedDataService.clearAadhaarData();
    this.baseURl = location.pathname;
    this.old_policy_no = localStorage.getItem("policy");
    this.date = this.datePipe.transform(
      this.subtract30Year(new Date()).getTime(),
      "yyyy-MM-dd"
    );
    this.initForm();
    this.age = this.dateToAgePipe.transform(this.date);
    if (this.baseURl === "/rbhealth-renewals/") {
      this.router.navigate(["renewals-quotes"]);
      this.isRenewable = true;
      this.encrypted = sessionStorage.getItem("encrypted_response");
      this.dataDecryptFromApi(this.encrypted);
    }
    if (this.baseURl === "/rbhealth/") {
      this.activatedRoute.queryParams.subscribe((params: any) => {
        if (params?.encrypt) {
          localStorage.setItem("apex_encrypt", params?.encrypt);
          this.handleEncryptedParams(params?.encrypt);
        } else {
          this.handleApexConsumerData();
        }
      });
    }

    if (this.baseURl === "/renewbuy-health/") {
      this.isCustomerMobileModalOpen = true;
      this.router.navigate(["buy-online"]);
      this.getQuotes(
        this.chooseplanForm.get("familysize")?.value,
        this.age,
        this.pincode,
        this.is_wellness,
        this.selectedPaymentPeriod
      );
    }
    this.getBrochureUrl();
  }

  /**
   * Retrieves and processes the consumer data from an encrypted token.
   *
   * @param encrypted - The encrypted token provided by the user.
   *
   * @remarks
   * This function makes a GET request to the API endpoint with the encrypted token as a query parameter.
   * It then subscribes to the response and sets the consumer data using the `setConsumerData()` function.
   * Finally, it fetches the quotes for the selected plan using the `getQuotes()` function.
   *
   * @returns {void}
   */

  handleEncryptedParams(encrypted: any) {
    this.apiService
      .getRequestedResponse(
        `${ApiConstants.ENCRYPTED_TOKEN}?encrypted=${encrypted}`
      )
      .subscribe((res) => {
        this.setConsumerData(res);
        localStorage.removeItem("is_partner_portal");
      });
  }

  /**
   * Retrieves and processes the consumer data from local storage.
   *
   * @remarks
   * This function checks if there is any consumer data in local storage and if the user is not from a partner portal.
   * If the conditions are met, it sets the consumer data in the form and fetches the quotes for the selected plan.
   * If the conditions are not met, it removes the consumer data from local storage.
   *
   * @returns {void}
   */
  handleApexConsumerData() {
    if ( localStorage.getItem("apex_encrypt") && !localStorage.getItem("is_partner_portal") ) {
      this.handleEncryptedParams(localStorage.getItem("apex_encrypt"));
    } else {
      localStorage.removeItem("apex_encrypt");
      this.getQuotes( this.chooseplanForm.get("familysize")?.value, this.age, this.pincode, this.is_wellness  ,this.selectedPaymentPeriod);
    }
  }

  /**
   * Sets the consumer data in the form and local storage.
   *
   * @param data - An object containing the consumer's date of birth and pincode.
   *
   * @remarks
   * This function is responsible for updating the form with the consumer's date of birth and pincode,
   * and storing the consumer's data in local storage.
   * It also calculates the consumer's age using the `dateToAgePipe` and stores it in the `countAge` property.
   *
   * @returns {void}
   */
  setConsumerData(data: any) {
    this.chooseplanForm.patchValue({
      dob: data["customer_dob"],
      pincode: data["customer_pincode"],
    });
    this.pincode = data["customer_pincode"];
    this.countAge = this.age = this.dateToAgePipe.transform( data["customer_dob"] );
    localStorage.setItem("token", data["agent_token"]?.split(" ")[1]);
  }
  /**  initializes the form with default values **/
  initForm(): void {
    this.chooseplanForm = this.formBuilder.group({
      plantype: ["Wellness + Health Insurance"],
      // dob: [new Date(1998, 0, 17), [Validators.required, this.dateValidator.bind(this)]], // Add dateValidator
      dob: ['', [Validators.required, this.dateValidator.bind(this)]], // Add dateValidator
      pincode: [ "", [ Validators.required, Validators.pattern("^[0-9]*$"), Validators.maxLength(6), Validators.minLength(6), ], ],
      familysize: [this.familySize[0]["key"], [Validators.required]],
    });
  }


  /**
   * calender min max handling
   */
  setCalenderRange() {
    const currentDate = new Date();
    this.maxDate = new Date( this.maxDate.setFullYear(currentDate.getFullYear() - 18) );
    this.minDate = new Date( this.minDate.setFullYear(currentDate.getFullYear() - 66)  );
    this.minDate.setDate(this.minDate.getDate() + 1);
  }
  getPlanType(event) {
    this.toggelValue.emit(event.value);
    if (event.value == "Wellness") {
      this.is_wellness = true;
      this.getQuotes( this.chooseplanForm.get("familysize")?.value, this.age, this.pincode, this.is_wellness  ,this.selectedPaymentPeriod);
    } else {
      this.is_wellness = false;
      this.getQuotes( this.chooseplanForm.get("familysize")?.value, this.age, this.pincode, this.is_wellness  ,this.selectedPaymentPeriod);
    }
  }

/** This function checks the selected payment option and performs the necessary action. If the selected option is "Regular", it calls the `getQuotes()` function to fetch the quotes for the selected plan. If the selected option is "EasyPay", it opens the EasyPay payment popup using the `eazyPayPaymentPopup()` function. **/ 
onPaymentOptionChange(value: string, event: Event) {
  if (value === 'EazyPay') {
    if (this.chooseplanForm.invalid) {
      event.preventDefault(); // Prevent the selection of the radio button
      this.chooseplanForm.markAllAsTouched();   // Mark the form controls as touched to show validation errors
    } else {
      // If the form is valid, allow the selection and trigger the popup.Check the base URL and conditionally call the popup with or without orderId
    if (this.baseURl === '/rbhealth-renewals/') {
      this.getUpgradeOrderdata(this.exist_orderId);
    } else {
      this.eazyPayPaymentPopup();
    }
    }
  } else if (value === 'Regular'){
    this.proceedWithEazyPay = false;
    this.selectedPaymentOption = value;
    this.getQuotes(this.chooseplanForm.get("familysize")?.value, this.age, this.pincode, this.is_wellness ,undefined);
  }
}

bimaPayCTA() {
  window.open( environment.bimaPayCalculatorRedirectUrl)
}

getPaymentPeriod($event: any) {
  this.selectedPaymentPeriod = $event.value;
  if(this.selectedPaymentPeriod === '10') this.tenMonthsBimapayMagmaPopup()
  this.getQuotes(this.chooseplanForm.get("familysize")?.value, this.age, this.pincode, this.is_wellness ,this.selectedPaymentPeriod);
}

proposerData : any;
getUpgradeOrderdata(orderId) {
  this.apiService .getRequestedResponse( `${ApiConstants.CREATE_ORDER}${orderId}` ) .subscribe((res) => {
    if(res){
      this.proposerData = res ;
      this.eazyPayPaymentPopup()
    }
    });
}



  /** This function is responsible for opening the EasyPay payment popup and handling the response from the dialog. **/
  eazyPayPaymentPopup() {
    let resWidth;
    let resTop;
    if (window.screen.width <= 767) {
      resWidth = "95%";
      resTop = "5%";
    } else {
      resWidth = this.customermobile["widthObtained"];
      resTop = "5%";
    }
   // Initialize body for data to pass to the dialog
  let body: any;

  if (this.baseURl === "/rbhealth-renewals/") {
    let proposerData = {
      name: this.proposerData['customer']['proposer']['name'],
      mobile: this.proposerData['customer']['proposer']['mobile_no'],
      dob: this.datePipe.transform(this.proposerData['customer']['proposer']['dob'], "yyyy-MM-dd"),
      pincode: this.proposerData['customer']['proposer']['pincode'],
      gender: this.proposerData['customer']['proposer']['gender'],
      pan: this.proposerData['customer']['proposer']['pan_number'],
    };

    // Assign proposerData to body
    body = proposerData;

  } else {
    // Create body from chooseplanForm for other cases
    body = {
      dob: this.datePipe.transform(this.chooseplanForm.get('dob')?.value, "yyyy-MM-dd"),
      pincode: this.chooseplanForm.get('pincode')?.value,
    };
  }

    const obj: DialogData = {
      type: this.eazyPayPopup["modalType"],
      width: this.eazyPayPopup["widthObtained"],
      height: this.eazyPayPopup["heightObtained"],
      classNameObtained: this.eazyPayPopup["classObtained"],
      minWidth: resWidth,
      dataInfo: {
        top: resTop,
        data: body,
      },
    };

    this.commonModal.openDialog(obj).subscribe((data: any) => {
      
      if (data && data.data) {
        this.proceedWithEazyPay = true;
        this.getLazyPayResponse = data?.data?.lead_id ? data?.data?.lead_id : data?.data?.eazyPayLeadId;
        const eazyPayData = {
          insurer : this.selectedPaymentOption,
          eazyPayLeadId: data?.data?.lead_id ? data?.data?.lead_id : data?.data?.eazyPayLeadId,
          selectedPaymentMonth: "6",
          status : true,
        };
        this.sharedDataService.storePaymentData(eazyPayData);
        this.getQuotes( this.chooseplanForm.get("familysize")?.value, this.age, this.pincode, this.is_wellness, this.selectedPaymentPeriod = "6" );
      } else if ( data === undefined || data === false || data.data === undefined || data.data === false ) {
        this.proceedWithEazyPay = false;
        this.selectedPaymentOption = "Regular";
      }
    });
  }

  // Custom validator function
  dateValidator(control: FormControl) {
    if (control.value == null) {
      return { validAgeFormat: true };
    }
    return null;
  }
  /**
   * Event handler for when the user enters a pincode.
   *
   * @param event - The pincode entered by the user.
   *
   * @remarks
   * This function checks if the pincode entered by the user is at least 6 characters long.
   * If it is, it calls the `getQuotes()` function to fetch the quotes for the selected plan.
   */
  isDobselected() {
    this.countAge = this.age = this.dateToAgePipe.transform(
      this.chooseplanForm.get("dob")?.value
    );
    this.pincode = this.chooseplanForm.get("pincode")?.value;
    if (this.chooseplanForm.get("pincode")?.valid) {
      this.getQuotes( this.chooseplanForm.get("familysize")?.value, this.age, this.pincode, this.is_wellness  ,undefined);
        this.proceedWithEazyPay = false;
        this.selectedPaymentOption = "Regular";
    }
  }
  /**
   * Event handler for when the user enters a pincode.
   *
   * @param event - The pincode entered by the user.
   *
   * @remarks
   * This function checks if the pincode entered by the user is at least 6 characters long.
   * If it is, it calls the `getQuotes()` function to fetch the quotes for the selected plan.
   */
  invalidPincode: boolean = false;
  getPincode(event) {
    if (event.length >= 6 && !isNaN(event)) {
      this.invalidPincode = false;
      this.checkPincodeEidelweiss = false;
      this.checkPincodeLiberty = false;
      this.checkPincodeGoDigit = false;
      this.isColHidden = false;
      this.isHidden = false;
      this.proceedWithEazyPay = false;
      this.selectedPaymentOption = "Regular";
      let url = `${ ApiConstants?.PINCODE_VALIDATION }?pincode=${event}&provider=${this.provider ? this.provider : ""}`;
      this.pincode = event;
      this.apiService .getRequestedResponse(url).subscribe((response) => {
          if (response["status"]) {
            this.getQuotes( this.chooseplanForm.get("familysize")?.value, this.age, this.pincode, this.is_wellness ,undefined );
            this.blockInsurer = [];
          } else {
            this.getQuotes( this.chooseplanForm.get("familysize")?.value, this.age, this.pincode, this.is_wellness ,undefined );
            if (response?.insurers == null) {
              this.invalidPincode = true;
              this.chooseplanForm.get("pincode")?.setErrors({ customError: response["message"] });
              this.chooseplanForm.get("pincode")?.markAsTouched();
            }
            if (response?.insurers) {
              this.blockInsurer = response?.insurers;
            }
          }
        });
    }
  }
  /**
   * Event handler for when the user changes the family size selection in the form.
   *
   * @param event - The selected family size option.
   *
   * @remarks
   * This function updates the form group with the selected family size option,
   * and then calls the `getQuotes()` function to fetch the quotes for the selected plan.
   */
  onFamilySizeChange(event) {
    const filteredFamilySize = this.familySize.filter(
      (item) => item.key === event.value
    );
    this.showfamilySize = filteredFamilySize.map((item) => item.value);
    if( this.baseURl !== '/rbhealth-renewals/' && (event?.value === '1,0' || event?.value === '2,2')) {
      this.isPortedToMagma = false;
      this.addOnId = '';
    }
    
    this.pincode = this.chooseplanForm.get("pincode")?.value;
    if (this.chooseplanForm.get("pincode")?.valid) {
      this.getQuotes( this.chooseplanForm.get("familysize")?.value, this.age, this.pincode, this.is_wellness  ,this.selectedPaymentPeriod);
    }
  }

  /**  Checks if all form controls in the chooseplanForm are invalid. **/
  isAllInvalid(): boolean {
    const dob = this.chooseplanForm.get("dob");
    const familysize = this.chooseplanForm.get("familysize");
    const pincode = this.chooseplanForm.get("pincode");
    const isInvalid = !((dob?.pristine || dob?.status == "VALID") && dob.value) || !(familysize?.status == "VALID" && familysize?.value) || !(pincode?.status == "VALID" && pincode?.value) || this.invalidPincode;
    return isInvalid;
  }


  isPlanDisabled(insurerName: string | undefined, extraCondition: boolean): boolean {
    // Check if the current URL is /rbhealth-renewals/
    const isRenewalUrl = this.baseURl === "/rbhealth-renewals/";
  
    // Always disable "Eidelweiss" when selectedPaymentPeriod is '10'
    if (this.selectedPaymentPeriod === '10' && insurerName === 'Eidelweiss') {
      return true;
    }
  
    // Logic for enabling/disabling based on proceedWithZuno and proceedWithMagma
    // if (isRenewalUrl && this.proceedWithZuno) {
    //   return insurerName === "Magma";
    // } else if (isRenewalUrl && this.proceedWithMagma) {
    //   return insurerName === "Eidelweiss";
    // }
  
    // Apply extra condition for "Magma" only when proceedWithEazyPay and selectedPaymentPeriod are met
    if (extraCondition) {
      return insurerName !== 'Magma';
    }
  
    // If none of the conditions match, enable the plan by default
    return false;
  }
  
    

  ngOnInit(): void {
    localStorage.removeItem("is_renewed_order");
    this.setCalenderRange();
  }
  /**
   * Fetches the quotes for a health insurance plan based on the user's input.
   *
   * @param familySize - The size of the user's family, represented as a string in the form of "1,0" (one adult) or "2,1" (two adults and one child).
   * @param age - The age of the user, represented as a number of years.
   * @param pincode - The pincode of the user's location.
   * @returns An array of objects, each representing a different plan and its associated premiums.
   */
  getQuotes(familysize, age, pincode, is_wellness ,selectedPaymentPeriod) {
    this.clearCookies()
    if (age >= 18 && age <= 85) {
      this.isSpinner = true;
      let url;
      if (this.baseURl === "/renewbuy-health/") {
        url = `${ ApiConstants?.GET_PLAN_LIST }?family_size=${familysize}&age=${age}&pincode=${pincode}&is_customer=false&is_wellness=${is_wellness}&provider=${ this.provider ? this.provider : "" }`;
      } else if (this.baseURl == "/rbhealth-renewals/") {
        url = `${ ApiConstants?.GET_PLAN_LIST }?old_policy_no=${localStorage.getItem( "policy" )}&family_size=${familysize}&age=${age}&pincode=${pincode}&is_customer=false&is_wellness=${is_wellness}&provider=${ this.provider ? this.provider : "" }`;
         
      } else {
        url = `${ ApiConstants?.GET_PLAN_LIST }?family_size=${familysize}&age=${age}&pincode=${pincode}&is_customer=false&is_wellness=${is_wellness}&provider=${ this.provider ? this.provider : "" }`;
      }
      if (selectedPaymentPeriod !== undefined) {
        url += `&easy_pay_month=${selectedPaymentPeriod}&bima_pay_id=${this.getLazyPayResponse}`;
      }
      // Check if addOnId exists and append add_on_type to the URL
      if (this.addOnId) {
        url += `&add_on_type=${this.addOnId}`;
    }
      this.apiService.getRequestedResponse(url).subscribe((res) => {
        this.isSpinner = false;
        this.processResponse(res);
      });
    
    }
  }

  /**
   * Retrieves the available add-ons from the Magma API and selects the "Maternity-Magma" add-on.
   *
   * @remarks
   * This function sends a GET request to the Magma API endpoint for retrieving the available add-ons.
   * It then filters the response to find the add-on with the name "Maternity-Magma".
   * If the add-on is found, its ID is stored in the `addOnId` property.
   * Finally, the function calls the `getQuotes` method to fetch the quotes for the health insurance plan,
   * passing the selected add-on ID as a parameter.
   *
   * @returns {void}
   */
  getAddonsData() {
    this.apiService
      .getRequestedResponse(ApiConstants.MAGMA_ADD_ONS)
      .subscribe((res: any) => {
        // Filter to find the addon with the name "Maternity-Magma"
        const maternityAddon = res.find( (addon: any) => addon.name === "Maternity-Magma" );
        // Check if the addon is found and log its ID
        if (maternityAddon) {
          this.addOnId = maternityAddon.id;     
        this.getQuotes( this.chooseplanForm.get("familysize")?.value, this.age, this.pincode, this.is_wellness , this.selectedPaymentPeriod );
        }
      });
  }

  /**
   * Opens a modal dialog to display the Magma renewals popup.
   *
   * @remarks
   * This function calculates the width and top position of the modal based on the screen width.
   * It creates a `DialogData` object with the necessary properties to open the modal.
   *
   * The function subscribes to the `openDialog` method of the `commonModal` service,
   * and handles the response from the modal.
   *
   * If the user selects the "Yes" option in the modal, it sets the `isPortedToMagma` flag to `true`,
   * retrieves the selected add-ons ID, sets the `proceedWithMagma` flag to `true`,
   * and clears the `proceedWithZuno` flag. It also sets the `provider` to 'Magma'
   * and calls the `getQuotes` function to fetch the quotes based on the selected plan.
   *
   * If the user selects the "No" option in the modal, it sets the `proceedWithMagma` flag to `false`,
   * clears the `proceedWithZuno` flag, and sets the `provider` to an empty string.
   * It also calls the `portToMagma` function with `false` as the parameter.
   */
  // magmaRenewalsPopup() {
  //   let resWidth;
  //   let resTop;
  //   if (window.screen.width <= 767) {
  //     resWidth = "95%";
  //     resTop = "5%";
  //   } else {
  //     resWidth = this.customermobile["widthObtained"];
  //     resTop = "2%";
  //   }

  //   const obj: DialogData = {
  //     type: this.magmaPopup["modalType"],
  //     width: this.magmaPopup["widthObtained"],
  //     height: this.magmaPopup["heightObtained"],
  //     classNameObtained: this.magmaPopup["classObtained"],
  //     minWidth: resWidth,
  //     dataInfo: {
  //       top: resTop,
  //       portMagma:  this.isPortedToMagma,
  //     },
  //   };

  //   this.commonModal.openDialog(obj).pipe(take(1)).subscribe((data: any) => {
  //     if( data?.data){
  //     this.isPortedToMagma =true;
  //       this.addOnId = data?.data?.addOnsId
  //       this.proceedWithMagma =true;
  //       this.proceedWithZuno = false;
  //       this.provider = 'Magma'
  //       this.getQuotes( this.chooseplanForm.get("familysize")?.value, this.age, this.pincode, this.is_wellness , this.selectedPaymentPeriod );
  //     } else {
  //       this.proceedWithMagma =false;
  //       this.proceedWithZuno = true;
  //       this.provider = '';
  //       this.portToMagma(false);

  //     }
  //   });
  // }

  // Function directly takes the checked value (true/false)
  portToMagma(checked: boolean) {
    if(this.baseURl === '/rbhealth-renewals/') {

      if (checked) {
        this.proceedWithMagma =true;
        this.proceedWithZuno = false;
        // this.magmaRenewalsPopup();
      } else {
        this.isPortedToMagma =false;
        this.proceedWithZuno = true;
        this.proceedWithMagma = false;
        this.provider = '';
        sessionStorage.removeItem( "maternityAddons");
        this.getQuotes( this.chooseplanForm.get("familysize")?.value, this.age, this.pincode, this.is_wellness , this.selectedPaymentPeriod );
      }
    } else {
      if (checked) {
        this.isPortedToMagma = true;
        this.getAddonsData()
      } else {
        this.addOnId = '';
        this.getQuotes( this.chooseplanForm.get("familysize")?.value, this.age, this.pincode, this.is_wellness , this.selectedPaymentPeriod );
        this.isPortedToMagma = false
      }
      
    }
  }


/** Clear cookies from the api **/
  clearCookies() {
    // Delete all cookies with dynamically calculated expiration time
    const cookies = document.cookie.split(";");
    const currentDate = new Date();
    currentDate.setTime(currentDate.getTime() - 1000); // Set to 1 second in the past
    const expires = `expires=${currentDate.toString()}`; // Format: Tue Oct 08 2024 12:12:14 GMT+0530 (India Standard Time)
    for (const cookie of cookies) {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos).trim() : cookie.trim();
      // Delete the cookie by setting its expiration date to the past
      document.cookie = `${name}=;${expires};path=${ document.location.pathname}/;domain=${document.domain};`;
    }
  }


  processResponse(res) {    
    // Convert response to array of key-value objects, filter 'common_data', and get the last item
    this.quotesList = Object.entries(res).map(([key, value]) => ({ key, value, }));

    const filteredQuotesList = this.quotesList.filter(({ key }) => key !== 'common_data');
    const lastItem = filteredQuotesList.at(-1);
    const lastValue = lastItem?.value || null;
  //  Pass the last item to sharedDataService if exists
  if (lastValue) {
    this.sharedDataService.getMagmaPremiumPlans(lastValue);
  }
    
  // Pass first item's value to getTopFeaturesPlans if available
    this.sharedDataService.getTopFeaturesPlans(this.quotesList[0]["value"]);

    // Process quotesFooter for icon and OPD properties
    this.quotesFooter = this.quotesList.at(-1)?.value;
    for (const key in this.quotesFooter) {
      const footer = this.quotesFooter[key];
      footer.icon = footer.name === "Health Assure" ? undefined : "/assets/images/health-v2/icons/hospital_new.svg";
      footer.isOpd = footer.name === "Health Assure" ? "Only OPD" : "Includes OPD";
    }
    const orderedKeys: any = [];
    for (let i = 0; i < this.quotesList.length; i++) {
      if (this.quotesList[i]["key"] == "common_data") {
        for (let j = 0; j < this.quotesList[i]?.value.length; j++) {
          orderedKeys[j] = this.quotesList[i]?.value[j]?.name;
        }
      }
    }
    this.quotesList.forEach((quote) => {
      if (quote.key !== "common_data") {
        const values = orderedKeys.map((key) => quote.value[key]);
        orderedKeys.forEach((key, index) => delete quote.value[key]);
        quote.value = values;
      }
    });

    // Add placeholder for blocked insurers in each quote value
    const quotesAvgValue = Math.max(1, Math.floor(this.quotesList.length / 2));
    this.quotesList.forEach((quote, index) => {
      if (this.blockInsurer && quote.key !== "common_data") {
        this.blockInsurer.forEach((blockKey) => {
          const keyIndex = orderedKeys.indexOf(blockKey);
          if (!quote.value[keyIndex]) {
            quote.value[keyIndex] = {
              quotesText:
                index === quotesAvgValue - 1
                  ? "Quotes are not available in this pincode"
                  : null,
            };
          }
        });
      }
    });
  }

  /** * Toggles the visibility of rows in a table based on the selected checkboxes. * * @param event - The event object that triggered the checkbox change. * @param index - The index of the checkbox that was changed. * * @remarks * This function is responsible for managing the visibility of rows in a table. * It adds or removes the index of the checkbox that was changed from the `checkedIndexes` array. * The `checkedIndexes` array is used to keep track of the checkboxes that are currently selected. * * @returns {void} */
  hideAndShow(event: MatCheckboxChange, index: number): void {
    if (event.checked) {
      // Checkbox is checked, add the index to the array
      this.checkedIndexes.push(index);
    } else {
      // Checkbox is unchecked, remove the index from the array
      const indexToRemove = this.checkedIndexes.indexOf(index);
      if (indexToRemove !== -1) {
        this.checkedIndexes.splice(indexToRemove, 1);
      }
    }
  }

  /** * Toggles the visibility of rows in a table based on the selected checkboxes. * * @remarks * This function is responsible for managing the visibility of rows in a table. * It adds or removes the 'hidden' attribute to the rows based on the selected checkboxes. * The `checkedIndexes` array is used to keep track of the checkboxes that are currently selected. * * @returns {void} */
  toggleHideShow(): void {
    if (this.checkedIndexes?.length > 0) {
      const rows = document.querySelectorAll(".table-wrapper .table-row-main");
      if (!this.isHidden) {
        // If rows are currently hidden, show only selected checkboxes
        for (let i = 0; i < rows.length; i++) {
          if (!this.checkedIndexes.includes(i)) {
            (rows[i] as HTMLElement).setAttribute("hidden", "true");
          }
        }
      } else {
        // If rows are currently shown, show all rows
        rows.forEach((row) => row.removeAttribute("hidden"));
        // Clear all selected checked values and indexes
        this.checkedIndexes = [];
      }
    }
    this.isHidden = !this.isHidden;
  }

  /** * Toggles the visibility of columns in a table based on the selected checkboxes. * * @param event - The event object that triggered the checkbox change. * @param index - The index of the checkbox that was changed. * * @remarks * This function is responsible for managing the visibility of columns in a table. * It adds or removes the index of the checkbox that was changed from the `checkedColumnIndexes` array. * The `checkedColumnIndexes` array is used to keep track of the checkboxes that are currently selected. * * @returns {void} */
  hideAndShowColumn(event: MatCheckboxChange, index: number): void {
    if (event.checked) {
      // Checkbox is checked, add the index to the array
      this.checkedColumnIndexes.push(index);
    } else {
      // Checkbox is unchecked, remove the index from the array
      const indexToRemove = this.checkedColumnIndexes.indexOf(index);
      if (indexToRemove !== -1) {
        this.checkedColumnIndexes.splice(indexToRemove, 1);
      }
    }
  }

  /** * Toggles the visibility of columns in a table based on the selected checkboxes. * * @remarks * This function hides or shows columns in a table based on the checkboxes selected by the user. * It retrieves the necessary elements (headers, rows, and footers) using querySelectorAll, * and then iterates through them to apply the 'hidden' attribute based on the selected checkboxes. * * @returns {void} */
  toggleHideShowColumn(): void {
    if (this.checkedColumnIndexes?.length > 0) {
      const headers = document.querySelectorAll(".table-sticky-col.plan");
      const rows = document.querySelectorAll(".table-row-main");
      const footers = document.querySelectorAll(".valign"); // Assuming 'valign' is the class of footer cells

      if (!this.isColHidden) {
        // Hide columns that are not checked
        headers.forEach((header, headerIndex) => {
          if (!this.checkedColumnIndexes.includes(headerIndex)) {
            (header as HTMLElement).setAttribute("hidden", "true");

            // Hide corresponding cells in each row
            rows.forEach((row) => {
              const cell = (row as HTMLElement).querySelectorAll(
                ".table-sticky-col"
              )[headerIndex];
              if (cell) {
                (cell as HTMLElement).setAttribute("hidden", "true");
              }
            });

            // Hide corresponding footer cells
            footers.forEach((footer, footerIndex) => {
              if (footerIndex === headerIndex) {
                (footer as HTMLElement).setAttribute("hidden", "true");
              }
            });
          }
        });
      } else {
        // Show all columns
        headers.forEach((header) => header.removeAttribute("hidden"));
        rows.forEach((row) => {
          const cells = (row as HTMLElement).querySelectorAll(
            ".table-sticky-col"
          );
          cells.forEach((cell) => cell.removeAttribute("hidden"));
        });
        footers.forEach((footer) => {
          (footer as HTMLElement).removeAttribute("hidden");
        });

        // Clear all selected checked values and indexes
        this.checkedColumnIndexes = [];
      }
    }
    this.isColHidden = !this.isColHidden;
  }

  isRowChecked(index: number): boolean {
    // Check if the index is in checkedColumnIndexes and if isColHidden is false
    return this.checkedIndexes.includes(index);
  }
  isColumnChecked(index: number): boolean {
    // Check if the index is in checkedColumnIndexes and if isColHidden is false
    return this.checkedColumnIndexes.includes(index);
  }

  /**Take a screenshot of a browser page**/
  fullCapture() {
    this.captureService .getImage(this.screen.nativeElement, true) .pipe( tap((img: string) => { this.img = img; this.share(img); }) ) .subscribe();
  }

  /**
   *current date set subtract 30 year date function
   **/
  subtract30Year(date) {
    date.setFullYear(date.getFullYear() - 30);
    return date;
  }

  /**
   * Selects a plan based on the given plan ID.
   *
   * @param plan_id - The ID of the plan to select.
   *
   * @remarks
   * This function first retrieves the plan data from the server using the given plan ID,
   * and then stores the retrieved plan data in the `this.planData` property.
   *
   * Next, the function calls the `this.sharedDataService.getPlanData()` method to
   * retrieve the user's personalized plan data, including their DOB, pincode, and other
   * relevant information.
   *
   * Finally, the function navigates the user to the "proposer" page, passing the plan ID
   * as a parameter.
   */

  selectPlan(plan_id, insurer , premium) {
    if (this.chooseplanForm.valid) {
      this.submitFormLoader = true;
      if (this.age)
        this.sharedDataService.getRetrievePlan("plan_id=" + plan_id);
      this.sharedDataService.retrievePlan.pipe(take(1)).subscribe((res) => {
        this.planData = res;
        this.submitFormLoader = false;
        this.sharedDataService.getPlanData({
          dob: this.datePipe.transform( this.chooseplanForm.get("dob")?.value, "yyyy-MM-dd" ),
          plan_id: this.planData.id,
          type: this.planData["plan_type"],
          max_child: this.planData["max_child"],
          is_opd: this.planData["is_opd"],
          plan_name: this.planData["name"],
          pincode: this.chooseplanForm.get("pincode")?.value,
          exist_orderId: location.pathname === "/rbhealth-renewals/" ? this.exist_orderId : "",
          amount: this.planData["discounted_amount"],
          insurer_name: insurer,
        });
        
        
        sessionStorage.setItem("insurer", insurer);
        if ( location.pathname === "/rbhealth/" || location.pathname === "/rbhealth-renewals/" ) {
          sessionStorage.removeItem('zunoPremium');
          sessionStorage.removeItem('magmaPremium');
          this.router.navigate(["proposer", plan_id]);
        } else if (location.pathname === "/renewbuy-health/") {
          this.openCustomerMobileModal(null, plan_id);
        }
        if (insurer === 'Magma' && this.addOnId) {
          sessionStorage.setItem("maternityPremium", premium)
          // Retrieve and update the session storage array in one line
          const maternityAddons = new Set(JSON.parse(sessionStorage.getItem("maternityAddons") || "[]"));
          
          // Add the current addOnId
          maternityAddons.add(this.addOnId);
        
          // Convert the Set back to an array and update session storage
          sessionStorage.setItem("maternityAddons", JSON.stringify([...maternityAddons]));
        }

        if(this.proceedWithEazyPay === true) {
          const eazyPayData = {
            insurer : this.selectedPaymentOption,
            eazyPayLeadId: this.getLazyPayResponse,
            selectedPaymentMonth: this.selectedPaymentPeriod,
            status : true,
          };
          this.sharedDataService.storePaymentData(eazyPayData);
        }

        if(this.selectedPaymentOption === 'Regular'){
          sessionStorage.removeItem("easyPayEligibilityConfirmData");
          this.sharedDataService.clearPaymentData();
        }
        
          
      });
    } else {
      this.markFormGroupTouched(this.chooseplanForm);
    }
  }

  /**checkout user Exist or not**/
  openCustomerMobileModal(event: any, plan_id) {
    if (!this.isChangePlanModal) {
      let resWidth;
      let resTop;
      if (window.screen.width <= 767) {
        resWidth = "95%";
        resTop = "5%";
      } else {
        resWidth = this.customermobile["widthObtained"];
        resTop = "5%";
      }

      const obj: DialogData = {
        type: this.customermobile["modalType"],
        width: this.customermobile["widthObtained"],
        height: this.customermobile["heightObtained"],
        classNameObtained: this.customermobile["classObtained"],
        minWidth: resWidth,
        dataInfo: {
          top: resTop,
          plan_id: plan_id,
          dob: this.datePipe.transform(
            this.chooseplanForm.get("dob")?.value,
            "yyyy-MM-dd"
          ),
          planData: "",
          // left: (xplacement) + 'px'
        },
      };
      this.commonModal.openDialog(obj).subscribe((data: any) => {
        if (data) {
          this.sharedDataService.getLeadsIdData(data["data"]);
          this.router.navigate(["proposer", data["data"]["plan"]]);
        }
      });
    } else {
      this.dialogRef.close({ event: close });
    }
  }

  /** Prepares the form data object with the form values and converts it to a JSON string on the basis of selectedPlan value **/
  goToPage(valid) {
    if (valid) {
      let formData;
      formData = {
        dob: this.datePipe.transform( this.chooseplanForm.get("dob")?.value, "yyyy-MM-dd" ),
        pincode: this.chooseplanForm.get("pincode")?.value,
        age: this.age,
      };

      // Convert the data object to a JSON string
      const formDataString = JSON.stringify(formData);
    }
  }


  /**
   * Recursively marks all controls in the form group as touched.
   *
   * @param formGroup - The form group to mark as touched.
   */
  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }
  /**
   * pincode not serviceable on popup modal
   **/
  pincodeNotServiceable() {
    let resWidth;
    let resTop;
    if (window.screen.width <= 767) {
      resWidth = "95%";
      resTop = "5%";
    }

    const obj: DialogData = {
      type: this.checkPincode["modalType"],
      width: this.checkPincode["widthObtained"],
      height: this.checkPincode["heightObtained"],
      classNameObtained: this.checkPincode["classObtained"],
      minWidth: resWidth,
      dataInfo: {},
    };

    this.commonModal.openDialog(obj).subscribe((res) => {
      this.chooseplanForm.get("pincode")?.reset();
      this.getQuotes(
        this.chooseplanForm.get("familysize")?.value,
        this.age,
        this.pincode,
        this.is_wellness ,
        this.selectedPaymentPeriod
      );
      this.isAllBlockInsurers = false;
    });
  }

  /**
   * This function is responsible for downloading the hospital network list based on the given parameters.
   *
   * @param networkList - The URL or path to the hospital network list PDF file.
   * @param insurer_name - The name of the insurer for whom the hospital network list is being downloaded.
   * @param blockInsurer - A flag indicating whether the insurer is blocked or not.
   *
   * @returns {void}
   */
  downloadHospitalList(networkList, insurer_name, blockInsurer) {
    if (networkList && (blockInsurer == null || blockInsurer == -1)) {
      if (insurer_name == "Digit") {
        const link = document.createElement("a");
        link.setAttribute("href", networkList);
        link.setAttribute("download", "Hospital Network List");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        const link = document.createElement("a");
        link.setAttribute("href", networkList);
        link.setAttribute("download", "Hospital Network List");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } else if ( insurer_name == "Digit" && (blockInsurer == null || blockInsurer == -1) ) {
      window.open( "https://www.godigit.com/health-insurance/digit-cashless-network-hospitals-list", "_blank" );
    }
  }
  getBrochureUrl() {
    let url = `${ApiConstants.BROCHURE_URL}?category=brochure`;
  
    // Conditionally append the additional query parameter
    if (this.baseURl === '/rbhealth-renewals/') {
      url += `&journey_type=upgrade`;
    }
    // Call the API service with the constructed URL
    this.apiService .getRequestedResponse(url) .subscribe((urlName) => { this.brochureUrlObject = urlName; });
  }
  
  /** Download broucher **/
  downloadBroucher(brochureUrl, pdftype) {
    const link1 = document.createElement("a");
    link1.setAttribute("href", brochureUrl);
    link1.setAttribute("target", "_blank"); // Open in a new tab
    link1.setAttribute("download", pdftype + ".pdf");
    document.body.appendChild(link1);
    link1.click();
    document.body.removeChild(link1);
  }
  share(img) {
    let resWidth;
    let resTop;
    if (window.screen.width <= 767) {
      resWidth = "95%";
      resTop = "5%";
    }

    const obj: DialogData = {
      type: this.shareObj["modalType"],
      width: this.shareObj["widthObtained"],
      height: this.shareObj["heightObtained"],
      classNameObtained: this.shareObj["classObtained"],
      minWidth: resWidth,
      dataInfo: {
        data: img,
      },
    };

    this.commonModal.openDialog(obj);
  }

  /**
   * This function generates a full URL for a logo image by appending the domain URL,
   * the pathname, and the search parameters of the given logoPath.
   *
   * @param logoPath - The original URL of the logo image.
   * @returns A string representing the full URL of the logo image.
   */
  getFullLogoUrl(logoPath: string): string {
    const url = new URL(logoPath);
    const urlObject = new URL(url);
    const newUrl =
      environment["domainUrl"] + urlObject.pathname + urlObject.search;
    return newUrl;
  }

  /**
   * Transforms a logo URL by removing the domain and any preceding path segments.
   *
   * @param logoUrl - The original URL of the logo image.
   * @returns A string representing the transformed URL of the logo image.
   *
   * @example
   * ```typescript
   * const originalUrl = "https://example.com/images/logo.png";
   * const transformedUrl = transformLogoUrl(originalUrl);
   * ```
   */
  transformLogoUrl(logoUrl: string): string {
    return logoUrl.replace(/^.*\/\/[^\/]+/, "");
  }
  /**
   * Navigates to the "network-hospitals" page.
   *
   * @remarks
   * This function is called when the user clicks on a button or link to view the list of
   * network hospitals. It uses the Angular Router to navigate to the specified route.
   *
   * @returns {void}
   */
  networkHospitals() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(["network-hospitals"])
    );
    window.open(url, "_blank");
  }

  // this function allowing the user to navigate to the "network-hospitals page"
  handleClick() {
    this.networkHospitals();
  }
  dataDecryptFromApi(encrypted) {

    this.sharedDataService.dataDecryptFromApi(encrypted);
    this.sharedDataService.dataDecryptFromApiRes.subscribe((res) => {
      this.magmaJourneyForUpgrade = res?.renewal_type;
    if(res?.renewal_type === 'upgrade' && res?.customer_journey === true)  {
      sessionStorage.setItem('is_customer_upgrade_journey', 'true');
    }

      // Check the condition and the popup flag
      if (this.magmaJourneyForUpgrade === 'upgrade' && this.urlEndPoint === 'renewals-quotes' && !this.isPopupShown) {
        this.getAddonsData();
        // this.magmaRenewalsPopup();
        this.isPopupShown = true; // Set the flag to true after showing the popup
      }        
      this.policy_number = localStorage.getItem("policy");
      if (!res["renewal_order_data"]) {
        this.pincode = res["customer_pincode"];
        if (res?.customer_dob) {
          this.age = this.dateToAgePipe.transform(res?.customer_dob);
          this.chooseplanForm.get("dob")?.patchValue(res?.customer_dob);
          this.chooseplanForm.get("dob")?.disable();
        }
        if (res["customer_pincode"]) {
          this.pincode = res?.customer_pincode;
          this.chooseplanForm .get("pincode") ?.patchValue(Number(res?.customer_pincode));
        }
        if (res["members"]) {
          this.chooseplanForm .get("familysize") ?.patchValue(`${res["members"][0]},${res["members"][1]}`);
        }
        this.isAllInvalid();
        this.getPincode(res?.customer_pincode);
        this.exist_orderId = res["order_id"];
        this.getQuotes( this.chooseplanForm.get("familysize")?.value, this.age, this.pincode, this.is_wellness  ,this.selectedPaymentPeriod);
      } else if (res["renewal_order_data"]) {
        let planObj = {
          new_plan: res["renewal_order_data"]["new_plan"],
          old_plan: res["renewal_order_data"]["old_plan"],
          message: res["renewal_order_data"]["message"],
          policy_number: this.policy_number,
          customer_journey: res["customer_journey"],
        };
        this.checkPreviousPolicy(planObj, this.previousPolicy);
      }
    });
  }
  checkPreviousPolicy(planObj, control) {
    let resWidth;
    let resTop;
    if (window.screen.width <= 767) {
      resWidth = "95%";
      resTop = "5%";
    } else {
      resWidth = this.errorMsgModalJson["widthObtained"];
      resTop = "5%";
    }

    const obj: DialogData = {
      type: control["modalType"],
      width: control["widthObtained"],
      height: control["heightObtained"],
      classNameObtained: control["classObtained"],
      minWidth: resWidth,

      dataInfo: {
        planObj,
      },
    };

    this.commonModal.openDialog(obj).subscribe((res) => {});
  }


    /** Opens the MagmaTopupComponent modal to allow users to top up their Magma balance. **/
    tenMonthsBimapayMagmaPopup() {
      let resWidth;
      let resTop;
      if (window.screen.width <= 767) {
        resWidth = "95%";
        resTop = "12%";
      } else {
        resWidth = this.tenMonthsBimapayMagma["widthObtained"];
        resTop = "8%";
      }
  
      const obj: DialogData = {
        type: this.tenMonthsBimapayMagma["modalType"],
        width: this.tenMonthsBimapayMagma["widthObtained"],
        height: this.tenMonthsBimapayMagma["heightObtained"],
        classNameObtained: this.tenMonthsBimapayMagma["classObtained"],
        minWidth: resWidth,
  
        dataInfo: {
          top: resTop,
        },
      };
      this.commonModal.openDialog(obj).subscribe((data: any) => {
      
      });
    }
  

}
